.banner {
	padding: 15px 0;
	text-align: center;
	//border-bottom: 6px solid theme(primary, dark);

	@include media-breakpoint-up(md) {
		border-bottom: none;
		.banner_contact { text-align: right; }
		.banner_visit { text-align: left; }
		.call { margin-bottom: 0; }
		.branding-title,
		.logo { margin: 0 auto; }
	}

	@include media-breakpoint-up(lg) {
		.branding-title,
		.logo {
			width: 270px;
			height: 133px;
		}
	}

}

