.menu {
	@extend %list_unstyled;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

	.menu_item {
		box-sizing: border-box;
		list-style-type: none;

		&.active .menu_link {
			cursor: default;
			&:hover { cursor: default; }
		}

		&.open {
			.dropdown_menu {
				display: block;
				height: auto;
			}
			.caret {
				@include transform(rotate(180deg));
			}
		}

	}

		.menu_link {
			@include transition(all ease-in-out 300ms);
			display: block;
			padding: 12px 15px;
			line-height: 1em; 
			text-decoration: none;
			&:hover,
			&:focus {
				@include transition(all ease-in-out 300ms);
				outline: none;
			}
		}

			.caret {
				display: inline-block;
				position: relative;
				vertical-align: baseline;
				//border: 1px solid red;
				width: 0.7em;
				height: 0.7em;
				background-image: url("/_/images/icons/caret-down-text.png");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}

				.dropdown-toggle:hover,
				.open .dropdown-toggle {
					.caret {
						background-image: url("/_/images/icons/caret-down-text.png");
					}
				}

		.dropdown_menu {

			@include transition(all ease-in-out 300ms);
			display: none;
			position: relative;
			z-index: 95000;
			margin: 0;
			padding: 1em;
			min-width: 280px;
			background-color: $white;

			.menu_item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				text-align: left;
			}

				.menu_link {
					padding-left: 10px;
					&:hover, &:focus {
						outline: none;					
					}				
				}

			@include media-breakpoint-up(md) {
				position: absolute;
			}

		}



