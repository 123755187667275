.modal-button {}

body.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.8);
	z-index: 99000;	
	cursor: pointer;

	&.show { display: block; }

}

.modal-dialog {
	position: relative;
	z-index: 99500;
	width: 90%;
	max-width: 640px;
	margin: 20px auto;
	padding: 15px;
	border-radius: 6px;
	border: 1px solid #ccc;
	background-color: #fff;
	cursor: default;

	p {
		margin-bottom: 0;
		padding: 10px 5px;
		font-size: 14px;
		line-height: 1.25em;
		&:nth-child(even) {
			background-color: #ddd;
		}
	}


}

	.modal-header {
		margin: 0 0 15px;
		padding: 0 0 10px;
		border-bottom: 1px solid #ccc;
	}

		.modal-title {
			margin: 15px 0 0;
			font-size: 18px;
		}