
.menu_sub {

	.menu-title {
		font-size: 1.325rem;
		letter-spacing: -0.0625em;
		text-transform: uppercase;
	}

	.menu_item:not(:last-child) {
		border-bottom: 1px solid rgba(black, 0.2);
	}

	.menu_item.active .menu_link {
		padding: 12px 9px 12px 19px;
		font-weight: $body-weight-bold;
		background: 	url("/_/images/icons/caret-right-secondary.png"),
						$white;
		background-repeat: no-repeat, repeat;
		background-position: -4px center, left center;
		background-size: 16px, 201% 100%;
		color: theme(secondary, base);
	}

		.menu_link {
			padding: 12px 9px 12px 8px;
			font-size: 15px;
			line-height: 1em;
			color: theme(text, base);
			background: 	url("/_/images/icons/caret-right-white.png"),
							linear-gradient(to right, theme(secondary, base) 50%, transparent 50%); // #fafafa
			background-position: -10px center, right center;
			background-repeat: no-repeat, repeat;
			background-size: 1px, 201% 100%;
			&:hover {
				padding: 12px 9px 12px 19px;
				background-position: -4px center, left center;
				background-size: 16px, 200% 100%;
				color: $white; //theme(primary, base);
			}
		}

}