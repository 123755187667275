.block_jumbo {

	padding: 15px;

	@include media-breakpoint-up(md) {
		.slideshow {
			padding: 5px;
			border: 1px solid #ccc;
			background-color: $white;
		}
	}

}