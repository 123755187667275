.banner_menu {
	
	border-bottom: 3px solid $white;
	background-color: theme(secondary, base);

	.menu {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;

		> .menu_item {
			display: inline-block;

			&.active {
				> .menu_link {
					background: transparent url("/_/images/layout/body-bg-min.jpg");
					color: theme(primary, base);
				}
			}

			&.open {
				> .menu_link {
					background-color: theme(primary, base);
					.caret { background-image: url("/_/images/icons/caret.png"); }
				}
			}

		}
	}

	.menu_link {
		display: block;
		padding: 20px 25px;
		@include font-size(14);
		font-weight: $body-weight-bold;
		letter-spacing: -0.0325em;
		text-transform: uppercase;
		white-space: nowrap;
		color: $white;

		&:hover {
			background-color: theme(primary, base);
			color: $white;
		}

		.caret { background-image: url("/_/images/icons/caret.png"); }

	}

	.dropdown_menu {
		padding: 10px;
		//border-top: 3px solid $white;
		background-color: theme(primary, base);
		box-shadow: 0 6px 5px -4px rgba(0,0,0,0.5);
		
		.menu_item {
			&:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.4); }
			&:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.35); }
			&.active {
				> .menu_link {
					background-color: theme(secondary, base);
					color: $white;
				}
			}
		}

		.menu_link {
			padding: 15px 25px;
			&:hover, &:focus {
				background-color: theme(primary, light);
			}
		}

	}

/*	@include media-breakpoint-only(md) {
		.menu > .menu_item {
			display: block;
			float: left;
			width: percentage(1/7);
		}
	}*/

}
