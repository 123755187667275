.media_objects_wrapper {}


.media_object {

	@include media-breakpoint-up(md) {
		@include make-flex;
		.media_image,
		.media_content {
			position: relative;
			box-sizing: border-box;
			min-height: 1px;
		}
		.media_image { width: 40%; }
		.media_content { width: 60%; padding-left: 20px; }
	}

	@include media-breakpoint-up(lg) {
		.media_image { width: 35%; }
		.media_content { width: 65%; }
	}

	@include media-breakpoint-up(xl) {}


}

	.media_image {
		margin-bottom: 15px;
	}

	.media_content {
		> *:last-child { margin-bottom: 0; }
	}

		.media-title {
			margin-top: 0;
		}




.products_wrapper {

	.product_object {

		margin: 0 0 2em;
		padding: 0 0 2em;
		border-bottom: 1px solid #ccc;
		box-shadow: 0 1px 0 0 #fff;

		&:first-child { margin-top: 5em; }
		&:not(:last-child) {
			margin: 0 0 2em;
		}

		@include media-breakpoint-up(md) {
			&:nth-child(odd) {
				.product_image { order: 2; }
				.product_content {
					order: 1;
					padding-left: 0;
					padding-right: 30px;
				}
			}
			&:nth-child(even) {
				.product_content {
					padding-left: 20px;
					padding-right: 0;
				}
				.product_image img {
					border-color: theme(accent, base);
				}
			}
		}

	}	

	.product_image img {
		padding: 0;
		border: 5px solid theme(highlight, base);
	}

	.product_content {
		
		//@include font-size(17);
		line-height: 1.5em;

		.media-title { @include font-size(24); }

		.spec-title {
			display: none;
			margin-top: 1em;
			@include font-size(16);
		}

		.spec-list {
			@include list_unstyled;
			@include font-size(13);
			line-height: 1em;

			li {
				padding: 3px 0 5px;
				&:not(:last-child) { border-bottom: 1px dotted #aaa; }
			}


			@include media-breakpoint-up(sm) {
				b {
					display: inline-block;
					width: 150px;
				}
			}

		}

	}

}


.brand_object {
	align-items: center;
	margin: 0 0 2em;
	padding: 0 0 2em;
	border-bottom: 1px solid #ccc;
	box-shadow: 0 1px 0 0 #fff;
}