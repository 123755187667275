.info_copyright {

	@include font-size(14);
	line-height: 1.3em;
	text-align: center; 

	a {
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

	.copyright-list { @include list_unstyled; }

	@include media-breakpoint-up(md) {
		.copyright-list {
			@include list_inline;
			li:not(:last-child) { margin-right: 1em; }
		}
	}


}