
.button_conversion {

	display: inline-block;
	padding: 15px 20px 15px 55px;

	font-family: $font-family-sans-serif;
	font-size: 1.125rem;
	font-weight: $body-weight-bolder;
	line-height: 1em;
	text-decoration: none;
	text-transform: uppercase;

	border-radius: 3px;
	border: 1px solid darken($color-danger, 10%);
	background-color: $color-danger;
	background-image: url("/_/images/icons/truck.png");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 55px auto;
	color: $white;
	box-shadow: 0 22px 9px -20px rgba(0,0,0,0.7);

    transform: scale(0.925);

	transition: none;
	transition-timing-function: linear;
	transition-duration: 300ms, 300ms, 200ms;
	transition-property: background-position, padding, transform, box-shadow;
	transition-delay: 0ms, 0ms, 0ms, 0ms;

    &:link, &:visited, &:active {
	    text-decoration: none;
	    border: 1px solid darken($color-danger, 10%);
	    background-color: $color-danger;
	    color: $white;
	}

    &:hover,
    &:focus {
		padding: 15px 55px 15px 20px;
        background-color: lighten($color-danger, 5%);
		background-position: right center;
		box-shadow: 0 22px 9px -20px transparent;
        color: $white;
        transform: scale(1);
		transition-duration: 600ms, 300ms, 100ms, 300ms;
		transition-delay: 200ms, 100ms, 0ms, 300ms;
    }  

    @include media-breakpoint-up(lg) { font-size: 1.3rem; }
    @include media-breakpoint-up(xl) { font-size: 1.5rem; }

}