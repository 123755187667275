.widget_review {

	border: 3px solid theme(review, base);
	box-shadow: none;

	.review {
		cite {
			vertical-align: middle;
			img {
				display: block;
				margin: 0 auto 10px;
				vertical-align: middle;
			}	
		}
	}

	@include media-breakpoint-only(md) {

		.review cite img {
			display: inline-block;
			margin: 0 7px 0 0;
		}

	}

}