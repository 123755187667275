.announce {
	padding: 15px;
	@include font-size(15);
	font-weight: $body-weight-bold;
	line-height: 1em;
	border-bottom: 3px solid $white;
	background-color: theme(secondary, base);
	color: $white;

	@include media-breakpoint-up(md) {
		background-color: transparent;
		background-image: linear-gradient(to right, theme(highlight, base), theme(highlight, dark), theme(highlight, base));
	}

}