$form_focus_border: 			$yellow;
$form_focus_bg: 				lighten($yellow, 40%);
$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form {

	font-size: 1rem;
	font-family: $font-family-sans-serif;
	line-height: 1em;

}

.form_section {
	overflow: hidden;
	margin: 0 0 2em;
	padding: 20px;
	border: 1px solid #ccc;

	legend {
		padding: 0 45px 0 10px;	
		@include font-size(18);
		font-weight: $body-weight-bold;
		text-transform: uppercase;
		letter-spacing: -0.0325em;
	}

}

	.form_group {
		margin: 0 0 8px;
		padding: 0;
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
	}

		label {
			display: block;
			margin: 0 0 1px 2px;
			font-size: 0.9rem;
			font-weight: $body-weight-bold;
			line-height: 1em;
			letter-spacing: -0.0275em;
		}

		.form_control {
			display: block;
			width: 100%;
			margin: 0 0 5px;
			padding: 9px 7px;
			font-size: 1.1rem;
			line-height: 1em;
			border-radius: 2px;
			border: 1px solid #ddd;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}
		}

			textarea.form_control {
				min-height: 150px;
				line-height: 1.275em;
			}

		.form_checkbox {
			margin: 0 0 8px;
 		   	vertical-align: top;
    		line-height: 1em;

    		label {
    			display: block;
    			min-height: 24px;
    			padding: 6px 0 7px 32px;
    			@include font-size(16);
    			font-weight: $body-weight-normal;
    			line-height: 1em;
    			cursor: pointer;
    		}
    		
    		.checkbox {
    			display: block;
    			margin: -3px 5px 0 -29px;
    			float: left;
    			width: 23px;
    			height: 23px;
    		}
    		
    		@include media-breakpoint-up(md) {
    			
    		}

		}


	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}