$jumbo-caption-bg: theme(primary, base);

.slider_jumbo {

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    line-height: 0px;

    .slick-prev,
    .slick-next {
        z-index: 999999;
        top: auto;
        bottom: 0;
        height: 79px;
        width: 44px;
        background-color: theme(secondary, base);
        background-position: center center;
        background-size: 14px;
        opacity: 0.7;
        &:hover {
            background-color: theme(primary, light);
            opacity: 1;
        }
    }

    .slick-prev {
        background-image: url("/_/images/icons/slide_prev-text.png");
    }
    .slick-next {
        background-image: url("/_/images/icons/slide_next-text.png");
    }

    .slide_wrap {
        display: block;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        text-align: center;
        background-position: center center;
        background-size: cover;
    }

        .slide-placeholder {
            width: 100%;
        }

    .slide_caption {
        padding: 10px;
        text-align: center;
        background-color: $jumbo-caption-bg;

        .caption-title {
            margin: 0;
            @include font-size(20);
            line-height: 1.375em;
            letter-spacing: -0.0375em;
        }

        .caption-text {
            font-size: 1.15rem;
            font-weight: $body-weight-bold;
            line-height: 1em;
            em {
                display: block;
                margin-top: 0.175em;
                font-size: 85%;
                font-weight: $body-weight-normal;
                line-height: 1em;
            }
        }
    }

    @include media-breakpoint-up(md) {

        .slide_01 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/01.jpg") }
        .slide_02 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/02.jpg") }
        .slide_03 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/03.jpg") }
        .slide_04 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/04.jpg") }
        .slide_05 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/05.jpg") }
        .slide_06 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/06.jpg") }
        .slide_07 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/07.jpg") }
        .slide_08 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/08.jpg") }
        .slide_09 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/09.jpg") }
        .slide_10 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/10.jpg") }
        .slide_11 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/11.jpg") }
        .slide_12 .slide_wrap { background-image: url("/_/images/slides/jumbo/lg/12.jpg") }

        .slide-placeholder { visibility: hidden; }

        .slick-prev,
        .slick-next {
            top: 35%;
            height: 110px;
            background-color: rgba(0,0,0,0.7);
            &:hover {
                background-color: rgba(0,0,0,0.7);
            }
        }

        .slick-prev { left: 6px; }
        .slick-next { right: 6px; }

        .slide_caption {
            padding: 12px 20px;
            text-align: left;
            .caption-title { @include font-size(24); }
            .btn { float: right; }
        }

    }



    @include media-breakpoint-up(lg) {

        &:hover {
            .slick-prev,
            .slick-next { opacity: 0.7 }
        }

        .slide_caption {
            position: absolute;
            bottom: 0; left: 0; right: 0;
            width: 100%;
            background-color: rgba(0,0,0,0.6);
            .caption-title {}
        }

        .slick-prev,
        .slick-next {
            top: 32%;
            width: 36px;
            height: 140px;
            opacity: 0;
            &:hover { opacity: 1; }
        }

    }

    @media (min-width: 1330px) {
        .slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }     
    }

}