.widget {

	@include card(20px 15px, 4px, $white);

	max-width: 420px;
	margin-left: auto;
	margin-right: auto;

	box-shadow: none;
	border: 4px solid theme(text, base);

	&.widget--primary { border-color: theme(primary, base); }
	&.widget--secondary { border-color: theme(secondary, base); }
	&.widget--highlight { border-color: theme(highlight, base); }
	&.widget--accent { border-color: theme(accent, base); }
	&.widget--success { border-color: theme(success, base); }
	&.widget--danger { border-color: theme(danger, base); }
	&.widget--warning { border-color: theme(warning, base); }
	&.widget--info { border-color: theme(info, base); }
	&.widget--text { border-color: theme(text, base); }
	&.widget--review { border-color: theme(review, base); }

}

	.widget-title {
		margin: 0 0 0.5em;
		font-size: 1.2rem;
		font-weight: $heading-weight-bolder;
		letter-spacing: -0.0425em;
		line-height: 1em;
		color: theme(text, base);
		.widget--primary & { color: theme(primary, base); }
		.widget--secondary & { color: theme(secondary, base); }
		.widget--highlight & { color: theme(highlight, base); }
		.widget--accent & { color: theme(accent, base); }
		.widget--success & { color: theme(success, base); }
		.widget--danger & { color: theme(danger, base); }
		.widget--warning & { color: theme(warning, base); }
		.widget--info & { color: theme(info, base); }
		.widget--text & { color: theme(text, base); }
		.widget--review & { color: theme(review, base); }
	}



	.widget-text {
		> *:last-child { margin-bottom: 0; }
		p { line-height: 1.375em; }
	}



.widget_product {
	img { margin-bottom: 10px; }
}



.widget_list {
	ul > li {
		margin: 0;
		padding: 5px 8px;

		&:not(:last-child) {
			border-bottom: 1px dotted #ccc;
		}

		&:nth-child(even) { background-color: #f8f8f8; }

		p {
			margin: 0 0 5px;
			font-size: 0.9rem;
			font-style: italic;
			line-height: 1.1em;
		}

	}
}



/*
.widget-subtitle {
	margin: 15px -24px;
	padding: 0.5em 24px;
	font-size: 1.1rem;
	font-weight: $heading-weight-bold;
	background-color: #e5e5e5;
}

.widget-note {
	font-size: 0.925rem;
	font-style: italic;
	line-height: 1.25em;

}

	.foot-note {
		font-weight: $body-weight-bolder;
		color: theme(danger, base);
	}*/
