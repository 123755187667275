// 240 x 118
// 270 x 133
// 300 x 147


.branding-title {
	width: 240px;
	height: 118px;
	margin: -40px auto 15px;
	font-size: 1rem;
	line-height: 1em;
	text-align: center;
}

.logo {
	display: block;
	overflow: visible;
	width: 240px;
	height: 118px;
	margin: -40px auto 15px;
	text-align: center;
	text-indent: -9999px;
	background: url("/_/images/layout/logo.png") top center no-repeat;
	background-size: contain;
}

	.logo-footer {
		display: block;
		max-width: 150px;
		margin: 0 auto 10px;
	}