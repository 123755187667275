.block_gallery {

	background-color: theme(highlight, light);

	.img-captioned {
		position: relative;
		padding: 4px;
		span {
			display: block;
			position: absolute;
			bottom: 5px; left: 5px; right: 5px;
			max-width: none;
			margin: 0;
			padding: 8px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			font-style: normal;
			line-height: 1em;
			text-transform: uppercase;
			background-color: rgba(0,0,0,0.7);
			color: $white;
		}
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.gallery_content {
			@include make-col(10);
		}
	}

}

	.gallery_title {}
