.site_info {
	padding: 30px 0;
	text-align: center;
	background-color: theme(secondary, base);
	color: $white;

	.page_front & {

		@include media-breakpoint-up(md) {
			position: relative;
			margin: 45px 0 0;
			padding: 0 0 30px;

			&::after {
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				border-left: 120px solid transparent;
				border-right: 120px solid transparent;
				border-bottom: 35px solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: transparent;
				border-bottom-color: theme(secondary, base);
				margin-left: -120px;
			}

			.info_company {
				@include make-flex();
				align-items: center;
				justify-content: center;
				.company_logo { width: 100%; }
				.company-call,
				.company-address {
					width: 50%;
					padding: 0 20px;
					text-align: left;
				}
				.company-call { text-align: right; }
			}

		}

	}

}