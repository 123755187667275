.call {
	line-height: 1em;
	.phone {
		display: inline-block;
		font-size: 1.5rem;
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.04em;
		&.contact-phone {}
		&.company-phone {
			color: $white;
		}
	}
}


