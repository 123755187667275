.address {
	margin: 0 0 15px;
	@include font-size(16);
	line-height: 1em;

	&.visit-address { 
		margin-bottom: 0;
		a {
			display: inline-block;
			padding: 5px 15px;
			position: relative;
			color: $color-text;
			text-decoration: none;
			div {
				position: absolute;
				@include make-flex;
				align-items: center;
				justify-content: center;
				top: 0; right: 0;
				bottom: 0; left: 0;
				@include font-size(13);
				font-weight: $body-weight-bolder;
				text-transform: uppercase;
				border-radius: 100px;
				background-color: #ff3030;
				color: $white;
				opacity: 0;
				@include transition(opacity ease-in-out 300ms);
			}
			&:hover div {
				opacity: 1;
				@include transition(opacity ease-in-out 300ms);
			}
		}
	}

}