.slideshow {

    .slide_caption {
        line-height: 1em;
        padding: 20px 15px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        background-color: theme(text, dark);
        color: $white;
    }

        .caption-title {
            margin: 0 0 0.5em;
            font-size: 1.2rem;
            font-weight: $heading-weight-bolder;
            line-height: 1em;
        }

        .caption-text {
            margin: 0;
            font-size: 0.95rem;
            line-height: 1.2em;
        }
            .caption-text+.caption-text { margin-top: 0.5em }

/*    @include media-breakpoint-down(xs) {
        .slick-prev,
        .slick-next { visibility: hidden; }
    }*/

}