.widget_menu {

	border-width: 2px;

	.widget-title a {
		color: theme(primary, base);
		text-decoration: none;
		&:hover {
			color: theme(secondary, base);
		}
	}

	.menu_item:not(:last-child) {
		border-bottom: 1px dotted #aaa;
	}

	.menu_item.active {
		.menu_link {
			background-color: theme(secondary, base);
			color: $white;
		}
	}

	.menu_link {
		color: theme(primary, base);
		&:hover {
			color: $white;
			background-color: theme(primary, base);
		}
	}

}