.block_welcome {

	text-align: center;

	hr {
		margin: 0.5em auto 1.5em;
		max-width: 90%;
	    background-color: #ddd;
	    //box-shadow: 0 1px 0 2px #fff;
	}

	.img-captioned {
		position: relative;
		padding: 4px;
		span {
			display: block;
			position: absolute;
			bottom: 5px; left: 5px; right: 5px;
			max-width: none;
			margin: 0;
			padding: 5px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			font-style: normal;
			line-height: 1em;
			text-transform: uppercase;
			background-color: rgba(0,0,0,0.7);
			color: $white;
		}
	}

	@include media-breakpoint-down(sm) {
		.gallery > .img-captioned:last-child {
			float: none;
			margin: 0 auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.welcome_content { @include make-col(10); }
	}

	@include media-breakpoint-up(xl) {
		.welcome_content { @include make-col(8); }
		.welcome_reviews { @include make-col(10); }
	}

}