.block {
	padding: 30px 0;
}

.block_title {}

	.block-title {
		margin-top: 0;
	}

.block_content {}

.block_more {
	text-align: center;
}