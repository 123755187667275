@mixin alert($bg: #ddd, $color: $color-text) {
	border: 1px solid $bg;
	background-color: lighten($bg, 25%);
	color: $color;
	.alert-title { color: darken($bg, 15%); }
}

.alert {
	
	@include alert();
	padding: 1em;
	border-radius: 3px;
	
	.button_close {
		float: right;
	}

}

	.alert-primary { @include alert($color-primary); }
	.alert-secondary { @include alert($color-secondary); }
	.alert-highlight { @include alert($color-highlight); }
	.alert-accent { @include alert($color-accent); }
	.alert-success { @include alert($color-success); }
	.alert-danger { @include alert($color-danger); }
	.alert-info { @include alert($color-info); }
	.alert-warning { @include alert($color-warning); }
	.alert-link { @include alert($color-link); }

	.alert-title {
		margin-top: 0;
		margin-right: 34px;
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		letter-spacing: -0.0325em;
	}

.block.block_alert {

	padding: 0;
	border-radius: 0;
	border: none;

	.container {
		position: relative;
		padding: 15px;
	}

	.alert_text {
		.alert-title {
			margin-bottom: 0.5em;
			color: theme(text, base);
		}

		p { line-height: 1.325em; }
	}

	.button_close {
		position: absolute;
		top: 0px; right: 15px;
	}

}

.block.alert_product {
	padding: 15px 0;
	.alert_media { padding: 30px 30px 15px; }
	.alert_text { padding: 0 30px; }

	@include media-breakpoint-down(md) {
		.alert_media { margin-top: -30px; }
	}

	@include media-breakpoint-up(lg) {
		.flex_wrap_alert {
			@include make-flex();
			align-items: center;
			.alert_media { width: 65%; padding: 0; }
			.alert_text { width: 35%; padding: 0 30px; }
		}
	}
}